import React, {FunctionComponent, useEffect, useState} from 'react';
import '@asseinfo/react-kanban/dist/styles.css'
import './StripeResetTool.scss'
import '../../../App.scss'
import {toast} from "react-toastify";
import MessageBox from "../../../components/MessageBox/MessageBox";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import OurPaperModal from "../../../components/OurPaperModal/OurPaperModal";
import Body1 from '../../../components/Typography/Body1';
import NegativeButton from "../../../components/CustomBottons/NegativeButton";
import OurToastContainer from "../../../components/OurToastContainer/OurToastContainer";
import {Auth} from "aws-amplify";
import {fetchPostBody} from "../../../utils/fetchUtils";
import {normalizeUsername} from "../../../utils/usernameUtils";

const StripeToolToastContainerId = "StripeToolToastContainerId";
const StripeToolToastErrorId = "StripeToolToastErrorId";
const StripeToolToastSuccessId = "StripeToolToastSuccessId";

const StripeResetTool: FunctionComponent<{}> = () => {

  const [username, setUsername] = useState<string>("");
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


  const notifyError = (message) => toast(message, {type: "error", pauseOnHover: true, toastId: StripeToolToastErrorId, containerId: StripeToolToastContainerId});
  const notifySuccess = (message) => toast(message, {type: "success", pauseOnHover: true, toastId: StripeToolToastSuccessId, containerId: StripeToolToastContainerId});

  useEffect(() => {
  }, [])

  function handleStripeReset() {
    setIsSubmitting(true);
    setIsConfirmPopupOpen(false);

    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody("admin/action", {"action": "stripeReset", "username": normalizeUsername(username)}, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then(() => {
            notifySuccess("Stripe reset successfully!");
            setUsername("");
          })
          .catch((err) => {
            notifyError("Something went wrong, find Yazeed please");
          })
          .finally(() => {
            setIsSubmitting(false);
          })
      })


  }

  return <>
    <MessageBox text="Stripe Reset Tool"/>
    <div className='laptop-screen-width-padding padding-inline-screen'>
      <div className="padding-bottom max-small-text-width">
        <TextField label="username"
                   fullWidth={true}
                   margin="dense"
                   onChange={(event) => setUsername(event.target.value)}
                   value={username}
                   variant="outlined"/>
        <div className="padding-block-self"/>
        <Button color="primary"
                disabled={isSubmitting || username.length === 0}
                onClick={(event) => setIsConfirmPopupOpen(true)}
                variant="contained"
        >
          Reset Stripe
        </Button>

        <OurPaperModal parentToggle={isConfirmPopupOpen}
                       setParentClose={() => setIsConfirmPopupOpen(false)}
                       leftSideElement={<h6 className="semi-bold">Reset {username} Stripe?</h6>}
        >
          <div className="center-text max-small-text-width flex-col-gap">
            <Body1 className="padding-bottom">
              Are you sure?
            </Body1>
            <div className="flex-row-gap">
              <NegativeButton onClick={() => setIsConfirmPopupOpen(false)}>
                no
              </NegativeButton>
              <Button color="primary"
                      onClick={handleStripeReset}
                      variant="contained">
                Yes
              </Button>
            </div>
          </div>
        </OurPaperModal>
      </div>
    </div>
    <OurToastContainer containerId={StripeToolToastContainerId}/>
  </>;

}

export default StripeResetTool;
