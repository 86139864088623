import React, {FunctionComponent, useEffect, useState} from 'react';
import '@asseinfo/react-kanban/dist/styles.css'
import '../../../App.scss'
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import MessageBox from '../../../components/MessageBox/MessageBox';
import {Auth} from "aws-amplify";
import {fetchGetBody, fetchPostBody} from "../../../utils/fetchUtils";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from '@material-ui/core/Radio';
import TextField from "@material-ui/core/TextField";
import OurDivider from "../../../components/OurDividers/OurDivider";
import AsyncActionButton from "../../../components/AsyncActionButton/AsyncActionButton";
import OurToastContainer from "../../../components/OurToastContainer/OurToastContainer";
import Body1 from '../../../components/Typography/Body1';
import UlLink from "../../../components/UlLink/UlLink";
import CopyText from "../../../components/CopyText/CopyText";
import ImgWithLoading from "../../../components/ImgWithLoading/ImgWithLoading";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import OurPaperModal from "../../../components/OurPaperModal/OurPaperModal";
import {Button} from "@material-ui/core";
import {normalizeUsername} from "../../../utils/usernameUtils";

const UserToolToastContainerId = "UserToolToastContainerId";
const UserToolToastErrorId = "UserToolToastErrorId";
const UserToolToastSuccessId = "UserToolToastSuccessId";

type InputTypes = 'username' | 'email';

const UserLookupTool: FunctionComponent<{}> = () => {
  const navigate = useNavigate();

  const notifyError = (message) => toast(message, {
    type: "error",
    pauseOnHover: true,
    toastId: UserToolToastErrorId,
    containerId: UserToolToastContainerId
  });
  const notifySuccess = (message) => toast(message, {
    type: "success",
    pauseOnHover: true,
    toastId: UserToolToastSuccessId,
    containerId: UserToolToastContainerId
  });


  const [inputType, setInputType] = useState<InputTypes>('username');
  const [userNameInput, setUserNameInput] = useState<string>('');
  const [userInfo, setUserInfo] = useState<Map<string, string>>(new Map());
  const [marketplaceUpdated, setMarketplaceUpdated] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isMarketplaceAddLoading, setIsMarketplaceAddLoading] = useState<boolean>(false);

  const [isIgnoreModalOpen, setIsIgnoreModalOpen] = useState<boolean>(false);
  const [toBeIgnoredUser, setToBeIgnoredUser] = useState<string>("");


  useEffect(() => {
  }, [])


  function handleSubmit() {
    setIsSubmitting(true);
    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchGetBody<Map<string, string>>(`admin/user?idType=${inputType}&input=${normalizeUsername(userNameInput)}`, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then((res) => {
            if (res === null) {
              notifyError("User not found!");
              return;
            }
            setUserInfo(res);
          })
          .catch((err) => {
            notifyError(err);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      })
  }

  function handleMarkAsNSFW(listingId: string) {
    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody<Map<string, string>>("admin/action", {
          "action": "markAsNsfw",
          "username": userNameInput,
          "listingId": listingId,
        }, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then(handleSubmit)
          .then(() => notifySuccess("Successfully marked as NSFW!"))
          .catch(() => notifyError("Failed to mark as NSFW, Yazeed can help"))
      })
  }

  function handleEnterKeyPress(event, fn: () => void) {
    if (event.keyCode === 13 && userNameInput.length > 0) {
      fn();
    }
  }

  function handleMarketplaceAdd(username: string, rank: number) {
    setIsMarketplaceAddLoading(true);
    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody<string>("admin/action", {
          "action": "AddUser",
          "username": username,
          "rank": rank,
        }, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then(res => res && res.includes("marketplace") ? Promise.reject(new Error(res)) : res)
          .then(handleSubmit)
          .then(() => setMarketplaceUpdated(true))
          .then(() => notifySuccess("User added successfully!"))
          .catch((err) => {
            if (err.message.includes("marketplace")) {
              notifyError(err.message)
            }
            notifyError("Something went wrong, talk to Yazeed")
          })
      })
      .finally(() => setIsMarketplaceAddLoading(false))
  }

  function handleUserIgnore() {
    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody("admin/action", {
          "action": "ignoreUser",
          "username": toBeIgnoredUser,
        }, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then(handleSubmit)
          .then(() => notifySuccess("User ignored successfully!"))
          .then(closeIgnoreModal)
          .catch((err) => notifyError("Something went wrong, talk to Yazeed"))
      })

  }

  function closeIgnoreModal() {
    setIsIgnoreModalOpen(false);
    setToBeIgnoredUser("");
  }

  return (
    <>
      <MessageBox text="User Lookup Tool"/>
      <div className='laptop-screen-width-padding padding-inline-screen'>
        <div className="padding-bottom max-small-text-width">
          <h6 className="semi-bold">Input Type:</h6>
          <OurDivider margin="dense"/>
          <RadioGroup
            onChange={(event) => setInputType(((event.target as HTMLInputElement).value) as InputTypes)}
            value={inputType}
          >
            <FormControlLabel control={<Radio/>}
                              label="username"
                              value="username"/>
            <FormControlLabel control={<Radio/>}
                              label="email"
                              value="email"/>
          </RadioGroup>
          <TextField label="username or email"
                     onChange={(event) => setUserNameInput(event.target.value)}
                     value={userNameInput}
                     onKeyDown={(event) => handleEnterKeyPress(event, handleSubmit)}
                     margin={"dense"}
                     variant={"outlined"}
                     fullWidth={true}/>
          <div className="padding-bottom"/>
          <AsyncActionButton action={handleSubmit}
                             disabled={!isSubmitting && userNameInput.length > 0}
                             color={"primary"}
                             text={"Get user data"}
                             variant={"contained"}/>
        </div>

        <div className="padding-top flex-col-no-align-gap">
          {userInfo && Object.entries(userInfo).map(([key, value]) => {
            if (key === "Commission types") {
              return <>
                <div className="flex-row-gap none-selectable padding-top">
                  <h6 className="semi-bold">{key}:</h6>
                </div>
                <OurDivider margin="dense"/>
                {value.map((comm_value) => {
                  return Object.entries(comm_value).map(([comm_key, comm_type_value]) => {
                    if (comm_key === "Images") {
                      return <>
                        <div className="flex-row-gap">
                          {/*// @ts-ignore*/}
                          {comm_type_value.map((image, index) => {
                            return <ImgWithLoading dimension="50px"
                                                   img={image}
                              // @ts-ignore
                                                   otherImages={comm_type_value}
                                                   currIndex={index}/>
                          })}
                        </div>
                        <Body1>-----</Body1>
                      </>
                    }

                    return <div className="flex-row-gap">
                      <Body1 className="semi-bold">{comm_key}:</Body1>
                      <Body1>{comm_type_value || []}</Body1>
                      {/*@ts-ignore*/}
                      {(comm_key === "Name" && comm_value["Is NSFW"] === "No") &&
                        <AsyncActionButton
                          action={() => handleMarkAsNSFW(comm_value["Listing ID"])}
                          color="primary"
                          text="Mark as NSFW"
                          style={{marginLeft: "auto"}}
                          variant="outlined"/>}
                    </div>
                  })
                })}

              </>
            }

            if (key === "Open Commissions" || key === "Closed Commissions") {
              return <div className="padding-block-self">
                <div className="flex-row-gap none-selectable">
                  <h6 className="semi-bold">{key}:</h6>
                </div>
                <OurDivider margin="dense"/>
                <div className="flex-col-no-align-gap">
                  {Array.from(value).map(commissionStatusAndLink => {
                    const commStatusAndLinkArr = commissionStatusAndLink as Array<string>;
                    const commissionId = commStatusAndLinkArr[1].split("/").pop();
                    // return
                    return <div className="flex-row-half-gap-wrap">
                      <Body1 className="semi-bold">{commStatusAndLinkArr[0]}:</Body1>
                      <CopyText copyText={commissionId!}>
                        <div className="padding-block-self">
                          <UlLink link={commStatusAndLinkArr[1]} text={commissionId}
                                  newTab={true}/>
                        </div>
                      </CopyText>
                    </div>
                  })}
                </div>
              </div>
            }

            if (key === "Notification preferences") {
              return <div className="padding-block-self">
                <div className="flex-row-gap none-selectable">
                  <h6 className="semi-bold">{key}:</h6>
                </div>
                <OurDivider margin="dense"/>
                <div className="padding-left">
                  {Object.entries(value).map(([notif_key, notif_value]) => {
                    return <div className="flex-row-gap">
                      <Body1 className="semi-bold">{notif_key}:</Body1>
                      <Body1>{notif_value || []}</Body1>
                    </div>
                  })}
                </div>
              </div>
            }

            if (key === "Marketplace app pending" && value === "Yes" && userInfo["Marketplace app ignored"] === "No") {
              if (userInfo["Is marketplace user"] === "No" && !marketplaceUpdated) {
                return <div className="flex-row-gap">
                  <Body1 className="semi-bold none-selectable">{key}:</Body1>
                  <Body1 linkify={true}>{value}</Body1>
                  {isMarketplaceAddLoading ? <CircularProgress/> :
                    <div className="flex-row-half-gap-wrap-no-grow">
                      <IconButton color="default"
                                  onClick={() => handleMarketplaceAdd(userInfo["Username"], 1)}
                      >
                        <h5 className="padding-inline-self">1</h5>
                      </IconButton>
                      <IconButton color="default"
                                  onClick={() => handleMarketplaceAdd(userInfo["Username"], 2)}
                      >
                        <h5 className="padding-inline-self">2</h5>
                      </IconButton>
                      <IconButton color="default"
                                  onClick={() => handleMarketplaceAdd(userInfo["Username"], 3)}
                      >
                        <h5 className="padding-inline-self">3</h5>
                      </IconButton>
                      <Button color="default"
                              onClick={() => {
                                setToBeIgnoredUser(userInfo["Username"]);
                                setIsIgnoreModalOpen(true);
                              }}
                              variant="contained">
                        Ignore
                      </Button>
                    </div>}
                </div>
              }
            }

            if (key === "Marketplace app pending" && (userInfo["Marketplace app ignored"] === "Yes" || userInfo["Is marketplace user"] === "Yes")) {
              return undefined;
            }

            if (key === "Marketplace app ignored" && userInfo["Is marketplace user"] === "Yes") {
              return undefined;
            }

            return <div className="flex-row-gap">
              <Body1 className="semi-bold none-selectable">{key}:</Body1>
              <Body1 linkify={true}>{value}</Body1>
            </div>
          })}
        </div>
        <OurPaperModal parentToggle={isIgnoreModalOpen}
                       setParentClose={closeIgnoreModal}
                       leftSideElement={<h6 className="semi-bold">Ignore {toBeIgnoredUser} request?</h6>}>
          <div className="flex-row-gap margin-auto-self padding-top">
            <AsyncActionButton color="primary"
                               action={handleUserIgnore}
                               text={"Yes"}
                               autoFocus={true}
                               variant="contained"/>
            <Button variant="contained"
                    onClick={closeIgnoreModal}>
              no
            </Button>
          </div>

        </OurPaperModal>
        <OurToastContainer containerId={UserToolToastContainerId}/>
      </div>
    </>
  );

}

export default UserLookupTool;
