import React, {FunctionComponent, useEffect, useState} from 'react';
import '@asseinfo/react-kanban/dist/styles.css'
import '../../../App.scss'
import {fetchPostBody} from "../../../utils/fetchUtils";
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import Body1 from "../../../components/Typography/Body1";
import AsyncActionButton from "../../../components/AsyncActionButton/AsyncActionButton";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import 'react-toastify/dist/ReactToastify.css';
import OurToastContainer from "../../../components/OurToastContainer/OurToastContainer";
import MessageBox from "../../../components/MessageBox/MessageBox";
import {normalizeUsername} from "../../../utils/usernameUtils";

const toastErrorId = "sign-up-error-toast-id"
const toastSuccessId = "sign-up-success-toast-id"
const toastContainerId = "sign-up-toast-container-id"

const ConfirmEmail: FunctionComponent<{}> = () => {
  const [emailInput, setEmailInput] = useState('');

  const notifyError = (message) => toast(message, {type: "error", pauseOnHover: true, toastId: toastErrorId, containerId: toastContainerId});
  const notifySuccess = (message) => toast(message, {type: "success", pauseOnHover: true, toastId: toastSuccessId, containerId: toastContainerId});


  const navigate = useNavigate();

  useEffect(() => {
  }, [])

  const handleInputChange = (event) => {
    setEmailInput(event.target.value);
  };

  function handleConfirm() {

    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody('admin/email', {email: normalizeUsername(emailInput)}, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then(() => {
            setEmailInput("");
            notifySuccess("Email set successfully!");
          }).catch((err) => {
            notifyError(err);
          })
      })
  }

  return (<div className='laptop-screen-width-padding padding-top padding-inline-screen'>
      <MessageBox text="Confirm Email"/>
      <div className="padding-block-self max-small-text-width">
        <Body1>Enter an email to confirm: <br></br></Body1>
        <Body1>Remember to ALWAYS use emails directly from the support ticket "FROM" field.</Body1>
        <div className="padding-block-self">
          <TextField label="Email"
                     name="email"
                     onChange={(event) => {
                       handleInputChange(event);
                     }}
                     value={emailInput}
                     fullWidth={true}
                     margin={"dense"}
                     type={"email"}
                     autoComplete={"email"}
                     variant={"outlined"}
          />
          <div className="padding-top"/>
          <AsyncActionButton action={handleConfirm}
                             color={"primary"}
                             text={"Confirm Email"}
                             variant={"contained"}/>
        </div>
      </div>
      <OurToastContainer containerId={toastContainerId}/>
    </div>
  );

}

export default ConfirmEmail;
