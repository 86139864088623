import React, {FunctionComponent, useEffect, useState} from "react";
import {toast} from "react-toastify";
import OurToastContainer from "../../../components/OurToastContainer/OurToastContainer";
import MessageBox from "../../../components/MessageBox/MessageBox";
import TextField from "@material-ui/core/TextField";
import {Auth} from "aws-amplify";
import {fetchPostBody} from "../../../utils/fetchUtils";
import OurPaperModal from "../../../components/OurPaperModal/OurPaperModal";
import Body1 from "../../../components/Typography/Body1";
import NegativeButton from "../../../components/CustomBottons/NegativeButton";
import Button from "@material-ui/core/Button";
import {normalizeUsername} from "../../../utils/usernameUtils";

const CommissionDeleteToastContainerId = "CommissionDeleteToastContainerId";
const CommissionDeleteToastSuccessId = "CommissionDeleteToastSuccessId";
const CommissionDeleteToastErrorId = "CommissionDeleteToastErrorId";

const DeleteCommission: FunctionComponent = () => {

  const [commissionId, setCommissionId] = useState<string>("");
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const notifyError = (message) => toast(message, {type: "error", pauseOnHover: true, toastId: CommissionDeleteToastSuccessId, containerId: CommissionDeleteToastContainerId});
  const notifySuccess = (message) => toast(message, {type: "success", pauseOnHover: true, toastId: CommissionDeleteToastErrorId, containerId: CommissionDeleteToastContainerId});

  useEffect(() => {

  }, []);

  function handleCommissionDelete() {
    setIsSubmitting(true);
    setIsConfirmPopupOpen(false);

    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody("admin/action", {
          "action": "commissionDelete",
          "commissionId": normalizeUsername(commissionId)
        }, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then(() => {
            notifySuccess("Commission deleted successfully!");
            setCommissionId("");
          })
          .catch((err) => {
            notifyError("Something went wrong, talk to Yazeed");
          })
          .finally(() => {
            setIsSubmitting(false);
          })
      })
  }

  return (<>
    <MessageBox text="Commission delete"/>

    <div className='laptop-screen-width-padding padding-inline-screen'>
      <div className="padding-bottom max-small-text-width">
        <TextField label="commission Id"
                   onChange={(event) => setCommissionId(event.target.value)}
                   value={commissionId}
                   margin={"dense"}
                   variant={"outlined"}
                   fullWidth={true}/>
        <div className="padding-bottom"/>

        <Button color="primary"
                disabled={isSubmitting || commissionId.length === 0}
                onClick={(event) => setIsConfirmPopupOpen(true)}
                variant="contained">
          Delete commission
        </Button>


        <OurPaperModal parentToggle={isConfirmPopupOpen}
                       setParentClose={() => setIsConfirmPopupOpen(false)}
                       leftSideElement={<h6 className="semi-bold">Delete Commission</h6>}
        >
          <div className="center-text max-small-text-width flex-col-gap">
            <Body1 className="padding-bottom">
              Are you sure?
            </Body1>
            <div className="flex-row-gap">
              <NegativeButton onClick={() => setIsConfirmPopupOpen(false)}>
                no
              </NegativeButton>
              <Button color="primary"
                      onClick={handleCommissionDelete}
                      variant="contained">
                Yes
              </Button>
            </div>
          </div>
        </OurPaperModal>
      </div>
    </div>

    <OurToastContainer containerId={CommissionDeleteToastContainerId}/>
  </>);
}

export default DeleteCommission;